<template>
	<div class="aside">
		<el-menu router class="el-menu-vertical-demo">
			<el-menu-item
				:index="item.router"
				v-for="(item, index) in asideData"
				:key="index"
			>
				<i class="el-icon-menu"></i>
				<span slot="title">{{ item.title }}</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
export default {
	name: "aslde",
	props: ["asideData"],
	data() {
		return {};
	},
};
</script>

<style scoped></style>
<style>
.aside .el-menu-vertical-demo:not(.el-menu--collapse) {
	width: 200px;
	min-height: 400px;
}
</style>
